import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { PageContainer } from '@pg/shared-ui';

import { useAuthState, useAuthDispatch, logout, storeMd5Hash } from '../../userContext';
import { useOrgState, resetOrg, useOrgDispatch } from '../../orgContext';

import useResponsive from '../../hooks/useResponsive';

import VerifyIdentityCard from '../../components/cards/VerifyIdentityCard';
import OrgDetailsBox from '../../components/boxes/OrgDetailsBox';
import VerifyIdentityForm from '../../components/forms/VerifyIdentityForm';

const VerifyIdentity = () => {
	const { errorMessage, md5hash: userMd5hash, user, loginViaRefresh } = useAuthState();
	const { isSm, isMd, isLg, isXl } = useResponsive();
	const { org } = useOrgState();
	const queryParams = useParams();
	const md5hash = queryParams?.md5hash || userMd5hash?.contact;
	// Default getting the rp's md5hash using the method we use to get the contact's md5hash since this is not always set
	const rpMd5hash = queryParams?.md5hash ? queryParams.rpMd5hash : userMd5hash?.rp;
	const authDispatch = useAuthDispatch();
	const orgDispatch = useOrgDispatch();
	const navigate = useNavigate();

	const VerifyIdentityCardWrapper = () => (
		<VerifyIdentityCard
			errorMessage={errorMessage}
			loginViaRefresh={loginViaRefresh}
			isSm={isSm}
		>
			<VerifyIdentityForm />
		</VerifyIdentityCard>
	);

	// Save md5hash
	useEffect( async () => {
		if ( md5hash && ( userMd5hash?.contact !== md5hash || userMd5hash?.rp !== rpMd5hash ) ) {
			logout( authDispatch );
			resetOrg( orgDispatch );
			storeMd5Hash( authDispatch, { contact: md5hash, rp: rpMd5hash } );
		} else if ( !loginViaRefresh && md5hash && user && user?.expiresAt > ( new Date().getTime() / 1000 ) ) {
			navigate( '/invoices' );
		} else if ( !md5hash ){
			navigate( '/404' );
		}
	}, [ md5hash ] );

	return (
		<PageContainer title='Payground: Verify Your Details' description='this is the identity verification page'>
			<Grid
				container
				justifyContent='center'
				sx={{
					mt: isLg ? 0 : 10,
					mb: isSm ? '150px' : isMd ? '200px' : isLg ? '300px' : 0,
					visibility: org ? 'visible' : 'hidden'
				}}
			>
				<Grid
					item
					sx={ {
						mr: isXl ? 10 : 0,
						maxWidth: isSm ? '100%' : isMd ? '95%' : isLg ? '70%' : isXl && '45%'
					} }
				>
					<OrgDetailsBox
						md5hash={md5hash}
						org={org}
						isXl={isXl}
						isSm={isSm}
						isLg={isLg}
					>
						<VerifyIdentityCardWrapper />
					</OrgDetailsBox>
				</Grid>
				{ ( isXl ) && (
					<Grid item sx={{ maxWidth: isSm ? '200px' : '500px' }}>
						<VerifyIdentityCardWrapper />
					</Grid>
				) }
			</Grid>
		</PageContainer>
	);
};

export default VerifyIdentity;
