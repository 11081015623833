import { initiateAnalytics } from '../api/analyticsService';
import {
	handleGraphQlErrors,
	requestOrganization,
	requestOrganizationByOrgCode
} from '../api/services';

const ORG_ERRORS = {
	default: 'Oops, something went wrong. Please try again.'
};

export async function getOrganization( dispatch, md5hash ) {
	try {
		dispatch( { type: 'REQUEST_ORG' } );
		const data = await requestOrganization( md5hash );
		if( data.name ) {
			// get payment method permissions
			data.acceptCards = data.providers?.some( provider => {
				return provider.acceptCards
			  } ) || false;
			  
			  data.acceptAch = data.providers?.some( provider => {
				return provider.acceptAch
			  } ) || false;
			dispatch( { type: 'ORG_SUCCESS', payload: data } );
			localStorage.setItem( 'currentOrg', JSON.stringify( data ) );
			initiateAnalytics( {visitorId:md5hash, orgName: data.name} );
			return data;
		}
		dispatch( { type: 'ORG_ERROR', error: ORG_ERRORS.default } );
	} catch ( response ) {
		const message = handleGraphQlErrors( response );
		dispatch( { type: 'ORG_ERROR', error: message } );
	}
	return false;
}

export async function getOrganizationByOrgCode( dispatch, orgCode ) {
	try {
		dispatch( { type: 'REQUEST_ORG' } );

		const data = await requestOrganizationByOrgCode( orgCode );

		if ( data.name ) {
			// get payment method permissions
			data.acceptCards = data.providers?.some( provider => {
				return provider.acceptCards
			  } ) || false;
			  
			  data.acceptAch = data.providers?.some( provider => {
				return provider.acceptAch
			  } ) || false;

			dispatch( { type: 'ORG_SUCCESS', payload: data } );
			localStorage.setItem( 'currentOrg', JSON.stringify( data ) );
			initiateAnalytics( { visitorId: orgCode, orgName: data.name  } );

			return data;
		}

		dispatch( { type: 'ORG_ERROR', error: ORG_ERRORS.default } );
	} catch ( error ) {
		const message = handleGraphQlErrors( error );

		dispatch( { type: 'ORG_ERROR', error: message } );
	}

	return false;
}

export function resetOrg( dispatch ) {
	dispatch( { type: 'LOGOUT' } );
	localStorage.removeItem( 'currentOrg' );
}
