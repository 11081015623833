import React from 'react';
import { Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { Footer, PGBranding, Spinner } from '@pg/shared-ui';
import { useOrgState } from '../../orgContext';
import { useAuthState } from '../../userContext';
import { useLoadingState } from '../../loadingContext'

import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL, HOME_UTM_SOURCE_CSP_URL } from '../../constants';

const MainWrapper = styled( 'div' )( () => ( {
	display: 'flex',
	minHeight: '100vh',
	overflow: 'hidden',
	width: '100%'
} ) );

const PageWrapper = styled( 'div' )( ( { theme } ) => ( {
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden',
	backgroundColor: theme.palette.background.default
} ) );

const urls = {
	homeUtmSourceCspUrl: HOME_UTM_SOURCE_CSP_URL,
	privacyPolicyUrl: PRIVACY_POLICY_URL,
	termsOfServiceUrl: TERMS_OF_SERVICE_URL
};

const FullLayout = () => {
	const { loading: orgLoading } = useOrgState();
	const { loading: authLoading } = useAuthState();
	const { loadingCount } = useLoadingState();
	return (
		<MainWrapper>
			 {( !!loadingCount || !!orgLoading || !!authLoading ) && <Spinner />}
			<Grid
				container
				spacing={2}
				direction='column'
				alignItems='center'
				justifyContent='space-between'
			>
				<Grid item sx={{ width: '100%', pt: 0 }}>
					<PageWrapper>
						<Container maxWidth='md'>
							<Outlet />
						</Container>
					</PageWrapper>
					<Grid item sx={{ width: '100%' }}>
						<PGBranding />
					</Grid>
				</Grid>
				<Grid item sx={{ width: '100%' }}>
					<Footer urls={urls} />
				</Grid>
			</Grid>
		</MainWrapper>
	);
};

export default FullLayout;
