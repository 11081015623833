/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPayment = /* GraphQL */ `
  mutation CreatePayment($payment: PaymentInput!) {
    createPayment(payment: $payment) {
      id
      message
      refId
      status
      uniqueRef
      __typename
    }
  }
`;
export const createPaymentFinancing = /* GraphQL */ `
  mutation CreatePaymentFinancing($financing: FinancingInput!) {
    createPaymentFinancing(financing: $financing) {
      status
      message
      agreementId
      borrowerSigningUrl
      __typename
    }
  }
`;
export const createPaymentPlan = /* GraphQL */ `
  mutation CreatePaymentPlan($paymentPlan: PaymentPlanInput!) {
    createPaymentPlan(paymentPlan: $paymentPlan) {
      id
      message
      refId
      status
      uniqueRef
      __typename
    }
  }
`;
export const createWallet = /* GraphQL */ `
  mutation CreateWallet(
    $contactId: ID
    $ach: WalletACH
    $creditCard: WalletCard
    $nickname: String
  ) {
    createWallet(
      contactId: $contactId
      ach: $ach
      creditCard: $creditCard
      nickname: $nickname
    ) {
      status
      message
      error
      wallet {
        id
        defaultMethod
        groupId
        nickname
        accountHolder
        card {
          lastFour
          expiry
          type
          __typename
        }
        ach {
          routingNumber
          accountNumber
          type
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const publishPaymentComplete = /* GraphQL */ `
  mutation PublishPaymentComplete($result: ResultInput!) {
    publishPaymentComplete(result: $result) {
      id
      message
      refId
      status
      uniqueRef
      __typename
    }
  }
`;
