import React from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { BaseCard, PageContainer, PaygroundErrorSvg } from '@pg/shared-ui';
import { useTheme } from '@emotion/react';

const Error = () => {
	const theme = useTheme();
	const isXs = useMediaQuery( theme.breakpoints.only( 'xs' ), { noSsr: true } );

	return (
		<PageContainer title='Payground: Error' description='Page Not Found'>
			<Grid
				container
				justifyContent='center'
			>
				<Grid item>
					<BaseCard sx={{ maxWidth: '800px' }}>
						<Grid
							container
							spacing={2}
							direction='column'
							alignItems='center'
							sx={{ p: isXs ? 0 : 3 }}
						>
							<Grid item>
								<PaygroundErrorSvg width='200px' height='217px' viewBox='0 0 281 307'/>
							</Grid>
							<Grid item container spacing={1}>
								<Grid item xs={12}>
									<Typography align='center' variant='h1'><strong>404</strong></Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography align='center' variant='h4'><strong>Oops! Page Not Found</strong></Typography>
								</Grid>
							</Grid>
						</Grid>
					</BaseCard>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default Error;
