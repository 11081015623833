import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Link, Typography } from '@mui/material';
import { HeaderCard, OrgHeader, PageContainer, RedirectSvg } from '@pg/shared-ui';
import { useOrgState } from '../orgContext';
import { useAuthState } from '../userContext';
import { cloudfrontEndpoint, FINANCE_REDIRECT_TIME } from '../constants';

const FinanceRedirect = () => {
	// Coming from payments, we don't want to be scrolled down on the page, we want to start at the top
	useEffect( () => {
		window.scrollTo( 0, 0 );
	}, [] );

	const { payment } = useAuthState();
	const navigate = useNavigate();
	useEffect( () => {
		if ( !payment || !payment.borrowerSigningUrl ) {
			navigate( '/404' );
		} else if ( !payment.loadFromStorage ) {
			setTimeout( () => {
				window.location.assign( payment?.borrowerSigningUrl );
			}, FINANCE_REDIRECT_TIME );
		}
	}, [ payment ] );

	const [ countdown, setCountdown ] = useState( payment.loadFromStorage ? 0 : FINANCE_REDIRECT_TIME / 1000 );
	useEffect( () => {
		if ( countdown > 0 ) {
			setTimeout( () => setCountdown( countdown - 1 ), 1000 );
		}
	}, [ countdown ] );

	const { org } = useOrgState();
	return (
		<PageContainer title='Payground: Finance Redirect' description='Finance Redirect!'>
			<HeaderCard header={<OrgHeader org={{ ...org, logo: `${cloudfrontEndpoint()}/${org.logo}` }} />}>
				<Grid
					container
					spacing={2}
					direction='column'
					alignItems='center'
				>
					<Grid item>
						<RedirectSvg viewBox='0 10 210 128' preserveAspectRatio='none' />
					</Grid>
					<Grid item>
						<Typography align='center' variant='h4'><strong>Payment Financing!</strong></Typography>
					</Grid>
					<Grid item>
						<Typography
							align='center'
							variant='h6'
							fontSize='16px'
						>
							<strong>You&apos;re being redirected to the third-party financing website.</strong>
						</Typography>
					</Grid>
					<Grid item>
						<Typography align='center' maxWidth='500px' variant='body1'>You&apos;ll receive further communication via
							email regarding your upcoming payments from the lender.</Typography>
					</Grid>
					<Grid item>
						<Typography align='center' variant='body1'>
							{countdown > 0 ?
								<strong>If you have not been redirected within {countdown} seconds,&nbsp;</strong>
								:
								<strong>If you have not been redirected,&nbsp;</strong>
							}
							<strong>
								<Link
									href={payment?.borrowerSigningUrl}
									target='_self'
									rel='noreferrer noopener'
								>
									click here
								</Link>.
							</strong>
						</Typography>
					</Grid>
					<br />
				</Grid>
			</HeaderCard>
		</PageContainer>
	);
};

export default FinanceRedirect;
