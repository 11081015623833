import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Grid } from '@mui/material';
import {
	HeaderCard,
	OrgHeader,
	PageContainer,
} from '@pg/shared-ui';

import { useAuthState } from '../userContext';
import { useOrgState } from '../orgContext';
import { useLoadingState } from '../loadingContext';

import { cloudfrontEndpoint } from '../constants';

import PaymentForm from '../components/forms/PaymentForm';

const Payments = () => {
	const [ paymentLocation, setPaymentLocation ] = useState( '' );

	const navigate = useNavigate();
	const { invoices, invoiceSelection, paymentError, walletsLoading } = useAuthState();
	const { org } = useOrgState();
	const { hideLoading, showLoading } = useLoadingState();

	useEffect( () => {
		if ( walletsLoading ) {
			return showLoading();
		}

		return hideLoading();
	}, [ walletsLoading ] );

	useEffect( () => {
		if ( invoices.length ) {
			const selectedInvoice = invoices.find( invoice => invoiceSelection?.includes( invoice.id ) ) || null;

			if ( selectedInvoice ) {
				setPaymentLocation( selectedInvoice?.location?.id );
			} else {
				// Handle the case when no selected invoice is found
				setPaymentLocation( null );
			}
		} else {
			navigate( '/invoices' );
		}
	}, [ invoices, invoiceSelection, navigate ] );

	return (
		<PageContainer title='Payground: Payments' description='Complete your payment' sx={{ p: 0 }}>
			<HeaderCard header={<OrgHeader org={{ ...org, logo: `${cloudfrontEndpoint()}/${org.logo}` }} />}>
				{paymentError && (
					<Grid item xs={12} sx={{ mb: 2 }}>
						<Alert severity='error'>{paymentError}</Alert>
					</Grid>
				)}
				<PaymentForm
					paymentLocation={paymentLocation}
				/>
			</HeaderCard>
		</PageContainer>
	);
};

export default Payments;
