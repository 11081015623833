import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';

import { useOrgState } from '../orgContext';
import { cloudfrontEndpoint } from '../constants';

const OrgLogo = ( ) => {
	const { org } = useOrgState();
	const [ imgError, setImgError ] = useState( false );

	return (
		// Hide the broken image icon if the image fails to load
		!imgError && org?.logo ?
			<Grid sx={{ width: '100px', height: '100px', my: 2 }} container alignContent='center' justifyContent='center'>
				<Box
					alt={org.name}
					component='img'
					onError={() => setImgError( true )}
					src={`${cloudfrontEndpoint()}/${org.logo}`}
					sx={{ maxHeight: '100px', maxWidth: '100px' }}
				/>
			</Grid>
			:
			<Box sx={{ my: 2 }} />
	);
};

export default OrgLogo;
