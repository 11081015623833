import React from 'react';
import { Container, experimentalStyled, Grid, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { linesImage, Footer, Spinner } from '@pg/shared-ui';

import { useOrgState } from '../../orgContext';
import { useAuthState } from '../../userContext';
import { useLoadingState } from '../../loadingContext';
import NavBar from '../fullLayout/navBar/NavBar';

import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL, HOME_UTM_SOURCE_CSP_URL } from '../../constants';

const urls = {
	homeUtmSourceCspUrl: HOME_UTM_SOURCE_CSP_URL,
	privacyPolicyUrl: PRIVACY_POLICY_URL,
	termsOfServiceUrl: TERMS_OF_SERVICE_URL
};

const MainWrapper = experimentalStyled( 'div' )( ( { theme } ) => {
	const isSm = useMediaQuery( theme.breakpoints?.down( 'sm' ) || '@media (max-width:599.95px)' );
	const isMd = useMediaQuery( theme.breakpoints?.down( 'md' ) || '@media (max-width:899.95px)' );
	return ( {
		display: 'flex',
		minHeight: '100vh',
		overflow: 'hidden',
		width: '100%',
		backgroundColor: theme.palette.background.ivory,
		backgroundImage: `url(${linesImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		backgroundPosition: `bottom ${ isSm ? '170px' : isMd ? '100px' : '70px' } center`
	} );
} );

const PageWrapper = experimentalStyled( 'div' )( () => ( {
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden'
} ) );

const ContactFullLayout = () => {
	const { loading: orgLoading } = useOrgState();
	const { loading: authLoading } = useAuthState();
	const { loadingCount } = useLoadingState();
	return (
		<MainWrapper>
			{( loadingCount || orgLoading || authLoading ) && <Spinner />}
			<Grid
				container
				spacing={2}
				direction='column'
				alignItems='center'
				justifyContent='space-between'
			>
				<Grid item sx={{ width: '100%', pt: 0 }}>
					<NavBar />
					<PageWrapper>
						<Container maxWidth='100%'>
							<Outlet />
						</Container>
					</PageWrapper>
				</Grid>
				<Grid item sx={{ width: '100%' }}>
					<Footer variant='white' urls={urls} />
				</Grid>
			</Grid>
		</MainWrapper>
	);
};

export default ContactFullLayout;
