import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Link } from '@mui/material';
import { BaseBox } from '@pg/shared-ui';

import {
	getOrganization,
	getOrganizationByOrgCode,
	resetOrg,
	useOrgDispatch
} from '../../orgContext';

import { HOME_UTM_SOURCE_CSP_URL } from '../../constants';
import OrgLogo from '../OrgLogo';

const OrgDetailsBox = ( {
	children,
	isSm,
	isLg,
	isXl,
	md5hash,
	org,
	orgCode
} ) => {
	const navigate = useNavigate();
	const orgDispatch = useOrgDispatch();

	const orgAddressFormatted = [
		org?.address?.streetAddress,
		org?.address?.streetAddress2,
		org?.address?.city,
		org?.address?.state && org?.address?.zip ? `${org.address.state} ${org.address.zip}` : org?.address?.zip
	]
		.filter( Boolean )
		.join( ', ' );

	const fetchDataByMd5Hash = async () => {
		resetOrg( orgDispatch );
		const response = await getOrganization( orgDispatch, md5hash );
		  
		if ( !response ) {
			navigate( '/404' );
		}
	};

	const fetchDataByOrgCode = async () => {
		resetOrg( orgDispatch );
		const response = await getOrganizationByOrgCode( orgDispatch, orgCode );
		  
		if ( !response ) {
			navigate( '/404' );
		}
	};

	useEffect( () => {
		if ( md5hash ) {
			fetchDataByMd5Hash();
		}
	}, [ md5hash ] );
		  
	useEffect( () => {
		if ( orgCode ) {
			fetchDataByOrgCode();
		}
	}, [ orgCode ] );

	return (
		<BaseBox sx={{
			pt: isSm ? 4 : 2,
			px: isSm ? 1 : 5,
		}}>
			<Grid
				container
				spacing={0}
				direction='column'
				alignItems='center'
			>
				<Grid item
					alignItems={isLg ? 'center' : 'left'}
					container
					spacing={1}
					direction='column'
				>
					<Grid
						container
						spacing={0}
						alignItems='center'
						justifyContent='flex-start'
						direction={isLg ? 'column' : 'row'}
						sx={{ mb: isSm ? -1 : 2 }}
					>
						<Grid item sx={{ mr: isXl && org?.logo ? 4 : 0 }}>
							<OrgLogo />
						</Grid>
						<Grid item sx={{ maxWidth: '65% !important' }}>
							<Typography
								align={ isLg ? 'center' : 'left' }
								variant={ isLg ? 'h5' : 'h4' }
							>
								<strong>{ org.name }</strong><br/> is requesting payment.
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						alignItems='center'
						sx={{
							mb: isSm ? 4 : 0,
							mt: isSm ? 4 : 0,
							pt: 0,
							pb: isSm ? 4 : isLg ? 2 : 0,
							width: isSm ? '100vw' : 'unset',
							backgroundColor: isSm ? '#fff' : 'unset'
						}}
					>
						{ !isXl && children }
					</Grid>
					<Grid item sx={{ pl: '0 !important' }}>
						<Typography
							align={isLg ? 'center' : 'left'}
							variant='body1'
							sx={{
								mb: 2,
								pl: isSm ? '24px !important' : '0 !important',
								pr: isSm ? '24px' : '0'
							}}
						>
							{ org.name } uses&nbsp;
							<Link
								underline='none'
								href={HOME_UTM_SOURCE_CSP_URL}
								variant={ isSm ? 'headline4' : 'headline1' }
								target='_blank'
								rel='noopener'
							>
								PayGround
							</Link>
							&nbsp;to collect payments from customers for outstanding balances.
						</Typography>
					</Grid>
					<Grid
						item
						align={ isSm ? 'center' : 'left' }
						sx={{
							mb: 2,
							pl: isSm ? '16px !important' : '0px !important',
							pr: isSm ? '16px' : '0'
						}}
					>
						<Grid item sx={{ mr: 1, display: 'inline-block' }}>
							<Typography
								variant='body1'
							>
								<strong>Got a question about your bill?</strong>
							</Typography>
						</Grid>
						<Grid item sx={{ display: 'inline-block' }}>
							<Typography
								variant='body1'
							>
								Please contact:
							</Typography>
						</Grid>
					</Grid>
					<Grid item sx={{
						pl: isSm ? '16px !important' : '0px !important',
						pr: isSm ? '16px' : '0'
					}}>
						<Typography
							align='left'
							variant='body1'
						>
							{ org.name }
						</Typography>
					</Grid>
					<Grid item sx={{
						pt: '0 !important',
						pl: isSm ? '16px !important' : '0 !important',
						pr: isSm ? '16px' : '0',
					}}>
						{
							!!orgAddressFormatted && (
								<Typography
									align={ isSm ? 'center' : 'left' }
									variant='body1'
								>
									{ orgAddressFormatted }
								</Typography>
							)
						}
					</Grid>
					<Grid item sx={{
						pt: '0 !important',
						pl: isSm ? '16px !important' : '0 !important',
						pr: isSm ? '16px' : '0',
					}}>
						<Typography
							align='left'
							variant='body1'
							sx={{
								wordBreak: isSm ? 'break-all' : 'normal'
							}}
						>
							{ `${ org.phone ? org.phone : '' } ${ org.phone && org.email ? '|' : '' } ${ org.email ? org.email : '' }` }
						</Typography>
					</Grid>
	
				</Grid>
			</Grid>
		</BaseBox>
	)
};

OrgDetailsBox.propTypes = {
	children: PropTypes.node,
	isSm: PropTypes.bool,
	isLg: PropTypes.bool,
	isXl: PropTypes.bool,
	md5hash: PropTypes.string,
	org: PropTypes.any,
	orgCode: PropTypes.string
};

export default OrgDetailsBox;
