/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganization = /* GraphQL */ `
  query GetOrganization {
    getOrganization {
      name
      logo
      email
      gateway
      address {
        streetAddress
        streetAddress2
        city
        state
        zip
        __typename
      }
      website
      phone
      isDobRequired
      financing {
        enabled
        minimum
        __typename
      }
      metaData
      capabilities {
        capability
        value
        __typename
      }
      providers {
        acceptAch
        acceptCards
        address {
          streetAddress
          streetAddress2
          city
          state
          zip
          __typename
        }
        id
        name
        phoneNumber
        website
        gateway
        __typename
      }
      dunningMessageConfig {
        minDaysPastDue
        message
        __typename
      }
      oldestUnpaidInvoice {
        invoiceId
        dueDate
        __typename
      }
      __typename
    }
  }
`;
export const getWallets = /* GraphQL */ `
  query GetWallets {
    getWallets {
      id
      defaultMethod
      nickname
      accountHolder
      accountNumber
      routingNumber
      bankAccountType
      methodType
      cardExpiry
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices {
    listInvoices {
      adjustment
      amount
      appointmentBalance
      discountAmount
      dueDate
      id
      insuranceAmountPaid
      invoiceDate
      invoiceItems {
        amount
        dateOfService
        description
        id
        quantity
        __typename
      }
      invoiceNumber
      isIntegrated
      isIntegration
      integration {
        adjustment
        appointmentBalance
        insuranceAmountPaid
        originalBilledAmount
        patientBalance
        patientPayments
        discounts
        __typename
      }
      items {
        amount
        dateOfService
        dateOfServiceEnd
        description
        id
        quantity
        __typename
      }
      location {
        acceptAch
        acceptCards
        address {
          streetAddress
          streetAddress2
          city
          state
          zip
          __typename
        }
        id
        name
        phoneNumber
        website
        gateway
        __typename
      }
      notes
      originalBilledAmount
      patientBalance
      stat
      __typename
    }
  }
`;
export const getOrganizationPayCode = /* GraphQL */ `
  query GetOrganizationPayCode {
    getOrganizationPayCode {
      name
      logo
      email
      gateway
      address {
        streetAddress
        streetAddress2
        city
        state
        zip
        __typename
      }
      website
      phone
      isDobRequired
      financing {
        enabled
        minimum
        __typename
      }
      metaData
      capabilities {
        capability
        value
        __typename
      }
      providers {
        acceptAch
        acceptCards
        address {
          streetAddress
          streetAddress2
          city
          state
          zip
          __typename
        }
        id
        name
        phoneNumber
        website
        gateway
        __typename
      }
      dunningMessageConfig {
        minDaysPastDue
        message
        __typename
      }
      oldestUnpaidInvoice {
        invoiceId
        dueDate
        __typename
      }
      __typename
    }
  }
`;
export const getContactInvoicesWithCredits = /* GraphQL */ `
  query GetContactInvoicesWithCredits(
    $invoiceMode: GetContactInvoiceWithCreditsMode!
    $invoiceIds: [String]!
    $statuses: [InvoiceStatus]!
    $includePaymentPlanInvoices: Boolean
  ) {
    getContactInvoicesWithCredits(
      invoiceMode: $invoiceMode
      invoiceIds: $invoiceIds
      statuses: $statuses
      includePaymentPlanInvoices: $includePaymentPlanInvoices
    ) {
      invoices {
        id: invoiceId
        location {
          id
          name
          email
          phoneNumber
          logo
          __typename
        }
        invoiceNumber
        amount
        stat
        createdDate
        dueDate
        paidDate
        discountPercent
        discountAmount
        notes
        invoiceDate
        integration: integrationData {
          adjustment
          appointmentBalance
          insuranceAmountPaid
          originalBilledAmount
          patientBalance
          patientPayments
          discounts
          __typename
        }
        activity {
          action
          invoiceId
          note
          time
          user
          __typename
        }
        items {
          quantity
          dateOfService
          id: itemId
          description
          recordTime
          amount: itemAmount
          __typename
        }
        accountId
        integrations
        contactDetails {
          firstName
          lastName
          externalId
          dob
          companyName
          __typename
        }
        organizationId
        orgId
        description
        contactId
        recordTime
        deletedDate
        createdBy
        creditsDebits {
          id
          amount
          __typename
        }
        creditApplied
        amountOwed
        creditRemaining
        __typename
      }
      associatedInvoices {
        id: invoiceId
        location {
          id
          name
          email
          phoneNumber
          logo
          __typename
        }
        invoiceNumber
        amount
        stat
        createdDate
        dueDate
        paidDate
        discountPercent
        discountAmount
        notes
        invoiceDate
        integration: integrationData {
          adjustment
          appointmentBalance
          insuranceAmountPaid
          originalBilledAmount
          patientBalance
          patientPayments
          discounts
          __typename
        }
        activity {
          action
          invoiceId
          note
          time
          user
          __typename
        }
        items {
          quantity
          dateOfService
          id: itemId
          description
          recordTime
          amount: itemAmount
          __typename
        }
        accountId
        integrations
        contactDetails {
          firstName
          lastName
          externalId
          dob
          companyName
          __typename
        }
        organizationId
        orgId
        description
        contactId
        recordTime
        deletedDate
        createdBy
        creditsDebits {
          id
          amount
          __typename
        }
        creditApplied
        amountOwed
        creditRemaining
        __typename
      }
      __typename
    }
  }
`;
