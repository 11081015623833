// Calculates the total amount owed (including Credit work)
export const calculateTotalOwed = ( invoices ) => {
	let total = 0;
	invoices.forEach( ( invoice ) => {
	  if ( typeof invoice.amountOwed === 'number' ) {
			total += Math.round( invoice.amountOwed * 100 );
	  }
	} );
	return total / 100;
};
  
export default calculateTotalOwed;