import React from 'react';
import Amplify from 'aws-amplify';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BuildTheme } from '@pg/shared-ui';
import '@pg/shared-ui/src/assets/fonts/ESKlarheitGrotesk/ESKlarheitGrotesk.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import Router from './routes/Router';
import { AuthProvider, initialState as userInitState } from './userContext';
import { OrgProvider, initialState as orgInitState } from './orgContext';
import awsconfig from './aws-exports';
import { graphQlEndpoint } from './constants';
import { LoadingProvider } from './loadingContext';

awsconfig.aws_appsync_graphqlEndpoint = graphQlEndpoint();
Amplify.configure( awsconfig );

const App = () => {
	const theme = BuildTheme();
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<LoadingProvider>
				<OrgProvider initialState={orgInitState}>
					<AuthProvider initialState={userInitState}>
						<Router />
					</AuthProvider>
				</OrgProvider>
			</LoadingProvider>
		</ThemeProvider>
	);
};

export default App;
