import React from 'react';
import {
	Box,
	Grid,
	Paper,
	Typography,
	useTheme
} from '@mui/material';
import { useFormikContext } from 'formik';

import { useAuthState } from '../../userContext';

function formatAmount( amount ) {
	let convertedAmount = amount;
	
	if ( typeof amount === 'string' ) {
	  convertedAmount = Number( amount );
	}
  
	return convertedAmount.toLocaleString( 'en-US', {
	  style: 'currency',
	  currency: 'USD'
	} );
}

const PaymentTotal = () => {
	const theme = useTheme();
	const { totalAmount } = useAuthState();
	const { values: { isPayPlanActive, installmentAmount, paymentStartDate } } = useFormikContext();

	const title = isPayPlanActive ? 'Payment Plan Total:' : 'Payment Total:';

	return (
		<Paper
			elevation={1}
			sx={{
				backgroundColor: theme.palette.background.lightGrey,
				px: 2,
				pt: 3,
				mt: 2,
				mb: 3,
			}}
		>
			<Grid
				container
				direction='row'
				spacing={1}
			>
				<Grid item xs={6}>
					<Typography variant='h6'>{title}</Typography>
				</Grid>
				<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Typography variant='h6'>{formatAmount( totalAmount )}</Typography>
				</Grid>
				{ isPayPlanActive && (
					<>
                    	<Grid item xs={6}>
							<Typography variant='h6'>First Installment:</Typography>
						</Grid>
						<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Typography variant='h6'>{`${formatAmount( installmentAmount )} on ${paymentStartDate}`}</Typography>
						</Grid>
						<Box sx={{ px: 1 }}>
							<p>
								{`You will be charged ${formatAmount( installmentAmount )} for the First Installment Payment dated ${paymentStartDate}.
							By creating this payment plan, you agree to pay a monthly payment of ${formatAmount( installmentAmount )} until the balance reaches $0.
							The final month's installment may have a payment less than the normal installment payment.
							The payment method you use today will be used to make all subsequent payments.`}
							</p>
						</Box>
					</>
				)}
			</Grid>
		</Paper>
	)
};

export default PaymentTotal;
