import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const useResponsive = () => {
	const theme = useTheme();

	// example media widths are defined here for unit testing, where theme breakpoints are undefined
	const isSm = useMediaQuery( theme.breakpoints?.down( 'sm' ) || '@media (max-width:599.95px)' );
	const isMd = useMediaQuery( theme.breakpoints?.down( 'md' ) || '@media (max-width:899.95px)' );
	const isLg = useMediaQuery( theme.breakpoints?.down( 'lg' ) || '@media (max-width:1199.95px)' );
	const isXl = useMediaQuery( theme.breakpoints?.up( 'lg' ) || '@media (min-width:1200px)' );

	return { isSm, isMd, isLg, isXl };
};

export default useResponsive;
