import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, FormGroup, Grid, Paper, Typography, capitalize, useMediaQuery, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { DunningMessage, FormSection, HeaderCard, OrgHeader, PageContainer, SelectAccordionInvoices, formatCurrency, invoicesFormikProps } from '@pg/shared-ui';
import { cloudfrontEndpoint } from '../constants';
import { useLoadingState } from '../loadingContext';
import convertDOBFormat from '../utils/convertDOBFormat';
import { useOrgState } from '../orgContext';
import { getInvoices, getWallets, updateInvoices, useAuthDispatch, useAuthState } from '../userContext';
import { calculateTotalOwed } from '../utils/calculateTotalOwed';
import { getCreditInvoices } from '../userContext/actions';
import enrichInvoices from '../utils/enrichInvoices';
import { calculateTotalAmount } from '../utils/calculateTotalAmount';

const FORMIK_PROPS = {
	initialValues: { ...invoicesFormikProps.initialValues },
	validationSchema: Yup.object( { ...invoicesFormikProps.yupValidation } ),
	enableReinitialize: true,
};

const hasCreditsApplied = ( invoice ) => {
	return typeof invoice?.creditApplied === 'number' && invoice.creditApplied > 0;
};

const shouldBeForceSelected = ( invoice ) => {
	return hasCreditsApplied( invoice ) || invoice?.amountOwed === 0;
};

const Invoices = () => {
	const theme = useTheme();
	const isXs = useMediaQuery( theme.breakpoints.only( 'xs' ) );
	const { hideLoading, loadingCount, showLoading } = useLoadingState();
	const { invoices, wallets, invoiceError, user, totalAmount, invoiceSelection } = useAuthState();
	const { org } = useOrgState();
	const authDispatch = useAuthDispatch();

	const [ debitInvoices, setDebitInvoices ] = useState( new Map( invoices.map( invoice => [ invoice.id, invoice ] ) ) );
	const [ creditsApplied, setCreditsApplied ] = useState( [] );
	const [ creditsInvoices, setCreditsInvoices ] = useState( [] );
	const [ selectedInvoices, setSelectedInvoices ] = useState( new Map( invoiceSelection.map( invoice => [ invoice.id, invoice ] ) ) );
	const [ totalValue, setTotalValue ] = useState( totalAmount || 0 );

	const oldestUnpaidInvoice = useMemo( () => {
		return org?.oldestUnpaidInvoice?.dueDate ? org.oldestUnpaidInvoice : user?.oldestUnpaidInvoice;
	}, [ org, user ] );

	const totalAmountCredits = useMemo( () => {
		return Math.abs( calculateTotalAmount( creditsInvoices ) );
	}, [ creditsInvoices ] );

	const totalAmountOwedDebits = useMemo( () => {
		return calculateTotalOwed( Array.from( debitInvoices.values() ) );
	}, [ debitInvoices ] );

	const totalAmountDebits = useMemo( () => {
		return calculateTotalAmount( Array.from( debitInvoices.values() ) );
	}, [ debitInvoices ] );

	const totalAppliedCredits = useMemo( () => {
		const appliedCreditsInvoices = creditsInvoices.filter( invoice => invoice?.creditsDebits?.length > 0 );
		return calculateTotalAmount( appliedCreditsInvoices )
	}, [ creditsInvoices ] );

	useEffect( () => {
		const loadData = async () => {
			const orgHasLoaded = org.acceptAch !== undefined && org.acceptCards !== undefined;
			if ( !wallets?.length && orgHasLoaded ) {
				getWallets( authDispatch, user.authToken, org.acceptAch, org.acceptCards, org );
			}
			showLoading();

			const [ debitsResponse, creditsResponse ] = await Promise.all( [
				!invoices.length ? getInvoices( authDispatch, user.authToken ) : { invoices },
				getCreditInvoices( user.authToken ),
			] );

			if ( debitsResponse.invoices ) {
				const debitInvoicesMap = new Map( debitsResponse.invoices.map( ( invoice ) => [ invoice.id, invoice ] ) );

				if ( debitsResponse.invoices.length === 1 ) {
					// If there is only one debit invoice, automatically select it
					const [ id, invoice ] = debitInvoicesMap.entries().next().value;
					setSelectedInvoices( new Map( [ [ id, invoice ] ] ) );
				} else {
					// If there are multiple debit invoices or none, set selectedInvoices to an empty Map
					setSelectedInvoices( new Map() );
				}

				setDebitInvoices( debitInvoicesMap );
			}

			// After fetching debitsResponse or when it is available
			if ( debitsResponse.invoices ) {
				const currentIDs = new Set();

				debitsResponse.invoices.forEach( invoice => {
					// Safely access creditsDebits array and iterate if it exists
					invoice.creditsDebits?.forEach( item => {
						if ( item && item.id ) {  // Ensure item and its id exist
							currentIDs.add( item.id );  // Add to the Set to ensure uniqueness
						}
					} );
				} );

				setCreditsApplied( Array.from( currentIDs ) );  // Convert the Set back to an array and update the state
			}

			if ( creditsResponse ) {
				const { invoices: creditInvoices, associatedInvoices } = creditsResponse;
				const enrichedCreditInvoices = enrichInvoices( { invoices: creditInvoices, associatedInvoices } );
				setCreditsInvoices( enrichedCreditInvoices );
			}

			hideLoading();
		};

		loadData();
	}, [ org.acceptAch, org.acceptCards ] );

	useEffect( () => {
		const autoSelectedInvoices = new Map();
		debitInvoices.forEach( ( invoice, id ) => {
			if ( shouldBeForceSelected( invoice ) || selectedInvoices.has( id ) ) {
				autoSelectedInvoices.set( id, invoice );
			}
		} );

		setSelectedInvoices( autoSelectedInvoices );
		setTotalValue( calculateTotalOwed( Array.from( autoSelectedInvoices.values() ) ) );
	}, [ debitInvoices ] );

	const handleInvoiceChecked = useCallback( ( id, isChecked ) => {
		if ( !isChecked && shouldBeForceSelected( debitInvoices.get( id ) ) ) {
			return;
		}

		const newSelectedInvoices = new Map( selectedInvoices );
		const invoiceAmount = debitInvoices.get( id );

		if ( isChecked ) {
			newSelectedInvoices.set( id, invoiceAmount );
		} else {
			newSelectedInvoices.delete( id );
		}

		setSelectedInvoices( newSelectedInvoices );
		setTotalValue( calculateTotalOwed( Array.from( newSelectedInvoices.values() ) ) );
	}, [ debitInvoices, selectedInvoices ] );

	const handleAllChecked = useCallback( ( isChecked ) => {
		if ( isChecked ) {
			const newSelectedInvoices = new Map( debitInvoices );
			setSelectedInvoices( newSelectedInvoices );
			setTotalValue( calculateTotalOwed( Array.from( newSelectedInvoices.values() ) ) );
		} else {
			const autoSelectedInvoices = new Map();
			debitInvoices.forEach( ( invoice, id ) => {
				if ( shouldBeForceSelected( invoice ) ) {
					autoSelectedInvoices.set( id, invoice );
				}
			} );

			setSelectedInvoices( autoSelectedInvoices );
			setTotalValue( calculateTotalOwed( Array.from( autoSelectedInvoices.values() ) ) );
		}
	}, [ debitInvoices ] );

	return (
		<Formik {...FORMIK_PROPS}>
			<PageContainer title="Payground: Invoices" description="Select your invoice(s) to pay">
				<HeaderCard header={<OrgHeader org={{ ...org, logo: `${cloudfrontEndpoint()}/${org.logo}` }} />}>
					{loadingCount ? null : (
						<form>
							<FormGroup>
								<Grid container spacing={1} direction="column" sx={{ px: 1 }}>
									<Grid item>
										{loadingCount || debitInvoices.size ? (
											<Grid container spacing={1} direction="column">
												<Grid container direction="column">
													<Grid item container justifyContent="center" sx={{ pt: 1, pb: 1 }}>
														<Typography
															variant="h6"
															align="center"
															style={{ color: theme.palette.text.header, fontWeight: '600' }}
														>
															Account Overview
														</Typography>
													</Grid>
													<Grid item container justifyContent="center" sx={{ pt: 0, pb: 4 }}>
														{user?.dob ? (
															<Typography
																variant="body1"
																align="center"
																style={{ color: 'black', fontWeight: '500' }}
															>
																{capitalize( user?.firstName || '' )} {capitalize( user?.lastName || '' )} |{' '}
																{convertDOBFormat( user.dob )}
															</Typography>
														) : (
															<Typography
																variant="body1"
																align="center"
																style={{ color: 'black', fontWeight: '500' }}
															>
																{capitalize( user?.firstName || '' )} {capitalize( user?.lastName || '' )}
															</Typography>
														)}
													</Grid>
													<FormSection>
														<Typography variant="h6" sx={{ mb: 2 }}>
															My Unpaid Invoices
														</Typography>
														<SelectAccordionInvoices
															invoices={Array.from( debitInvoices.values() )}
															selectedInvoices={Array.from( selectedInvoices.keys() )}
															handleInvoiceChecked={handleInvoiceChecked}
															handleAllChecked={handleAllChecked}
															invoicesAmount={totalAmountOwedDebits}
															hideTimeline
														/>
													</FormSection>
													{!!creditsInvoices?.length && (
														<FormSection>
															<Grid container spacing={1} direction="column">
																<Grid item sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
																	<Typography variant="h6" align="left">
																		Credits
																	</Typography>
																	<Typography
																		align="right"
																		component="span"
																		sx={{ display: 'inline', fontSize: 20, fontWeight: 400 }}
																	>
																		Total:{' '}
																		<Typography
																			component="span"
																			sx={{ color: theme.palette.success.main, display: 'inline', fontSize: 20, fontWeight: 700 }}
																		>
																			{formatCurrency( totalAmountCredits, 'NEGATIVE' )}
																		</Typography>
																	</Typography>
																</Grid>
																<Grid item>
																	<SelectAccordionInvoices
																		invoices={creditsInvoices}
																		selectedInvoices={[]}
																		handleInvoiceChecked={() => {}}
																		handleAllChecked={() => {}}
																		invoicesAmount={calculateTotalOwed( creditsInvoices )}
																		isCredits
																		hideTimeline
																	/>
																</Grid>
															</Grid>
														</FormSection>
													)}
												</Grid>
												<FormSection style={{ paddingBottom: 0 }}>
													<Grid container direction="row" spacing={1}>
														<Grid item xs={6}>
															<Typography variant="h6" fontWeight="600">
																Payment Total:
															</Typography>
														</Grid>
														<Grid item xs={6} container justifyContent="flex-end">
															<Typography variant="h6">
																{totalAppliedCredits !== undefined && totalAppliedCredits !== 0 ? (
																	<span style={{ color: theme.palette.text.header, fontWeight: '400' }}>
                                    									({formatCurrency( totalAppliedCredits, 'POSITIVE' )} credits applied){' '}
																	</span>
																) : null}
																<span style={{ fontWeight: '600' }}> {formatCurrency( totalValue )} </span>
															</Typography>
														</Grid>
													</Grid>
												</FormSection>
												{org?.capabilities?.some( ( item ) => item.capability === 'dunning_message' && item.value === true ) && (
													<Grid sx={{ my: 3 }} item>
														<DunningMessage
															dunningMessageConfig={org.dunningMessageConfig}
															oldestUnpaidInvoice={oldestUnpaidInvoice}
														/>
													</Grid>
												)}
												<Grid item container justifyContent="center">
													<Button
														color="primary"
														disabled={totalValue <= 0 || ( totalAmountCredits >= totalAmountDebits )}
														variant="contained"
														onClick={() =>
															updateInvoices(
																authDispatch,
																Array.from( debitInvoices.values() ),
																Array.from( selectedInvoices.keys() ),
																totalValue,
																creditsApplied
															)
														}
														component={Link}
														to="/payment"
														fullWidth={isXs}
														sx={{ borderRadius: 2, height: '50px', minWidth: '300px' }}
													>
														<Typography variant="button">Continue To Payment</Typography>
													</Button>
												</Grid>
											</Grid>
										) : (
											<Paper elevation={1} sx={{ backgroundColor: theme.palette.background.lightGrey, px: 2, py: 3 }}>
												{invoiceError ? (
													<Typography align="center">{invoiceError}</Typography>
												) : (
													<Typography align="center">There are currently no unpaid invoices.</Typography>
												)}
											</Paper>
										)}
									</Grid>
								</Grid>
							</FormGroup>
						</form>
					)}
				</HeaderCard>
			</PageContainer>
		</Formik>
	);
};

export default Invoices;