import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Grid } from '@mui/material';
import { useFormikContext } from 'formik';

import { useAuthState, createWallet, useAuthDispatch } from '../../userContext';
import { transformCreateWalletPayload } from '../../api/transformPaymentPayload';

const CreateWalletFormWrapper = ( { children, showButton } ) => {
	const authDispatch = useAuthDispatch();
	const paymentMethodFormRef = useRef( null );

	const { values } = useFormikContext();
	const { createWalletError, user } = useAuthState();

	const createWalletMethod = async () => {

		const { payload, authToken } = transformCreateWalletPayload( {
			user,
			values,
		} );

		if ( !( payload && authToken ) ) {
			console.error( 'Missing payload or authToken', { payload, authToken } );

			return;
		}

		createWallet( {
			dispatch: authDispatch,
			authToken,
			payload
		} );
	}

	useEffect( () => {
		if ( paymentMethodFormRef.current && createWalletError ) {
			paymentMethodFormRef.current.scrollIntoView( { behavior: 'smooth', block: 'start' } );
		}
	}, [ createWalletError ] );

	return (
		<Grid
			container
			spacing={2}
			direction='column'
			ref={paymentMethodFormRef}
		>
			{createWalletError && (
				<Alert sx={{ mb: 2 }} severity='error'>{createWalletError}</Alert>
			)}
			<Grid item>
				{children}
			</Grid>
			{ showButton && (
				<Grid item justifyContent="flex-start">
					<Button
						color="primary"
						variant="contained"
						sx={{ marginTop: 1, borderRadius: 2, padding: 1.5 }}
						onClick={createWalletMethod}
					>
						Add To Wallet
					</Button>
				</Grid>
			) }
		</Grid>
	)
};

CreateWalletFormWrapper.propTypes = {
	children: PropTypes.node,
	showButton: PropTypes.bool,
};

export default CreateWalletFormWrapper;
