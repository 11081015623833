import React from 'react';
import { Grid, Link } from '@mui/material';
import { useTheme } from '@emotion/react';
import { PaygroundLogoSvg } from '@pg/shared-ui';
import { HOME_UTM_SOURCE_CSP_URL } from '../../../constants';

const NavBar = () => {
	const theme = useTheme();
	return (
		<Grid
			item
			sx={{ backgroundColor: theme.palette.background.white, boxShadow: theme.shadows[ 6 ], px: 2, py: 2 }}
			columnSpacing={7}
			container
		>
			<Grid item>
				<Link
					underline='none'
					href={HOME_UTM_SOURCE_CSP_URL}
					variant='caption'
					target='_blank'
					rel='noopener'
					sx={{ display: 'flex' }}
				>
					<PaygroundLogoSvg viewBox='0 0 150 24' width='126' height= '20' />
				</Link>
			</Grid>
		</Grid>
	);
};

export default NavBar;
