export const getUserObject = ( data ) => {
	try {
		const parsed = JSON.parse( atob( data.access_token.split( '.' )[ 1 ] ) );
		return {
			...parsed,
			authToken: data.access_token,
			tokenType: data.token_type,
			expiresAt: data.expire_at,
		};
	} catch ( e ) {
		return null;
	}
};

const user = localStorage.getItem( 'currentUser' )
	? getUserObject( JSON.parse( localStorage.getItem( 'currentUser' ) ) )
	: '';

const md5hash = localStorage.getItem( 'md5hash' )
	? JSON.parse( localStorage.getItem( 'md5hash' ) )
	: '';

const orgCode = localStorage.getItem( 'orgCode' )
	? JSON.parse( localStorage.getItem( 'orgCode' ) )
	: '';

const payment = localStorage.getItem( 'payment' )
	? { ...JSON.parse( localStorage.getItem( 'payment' ) ), loadFromStorage: true }
	: '';

const createWalletInitialState = {
	createWalletError: '',
}

export const initialState = {
	...createWalletInitialState,
	errorMessage: null,
	invoices: [],
	creditsApplied: [],
	invoiceSelection: [],
	wallets: [],
	payment: payment || '',
	loading: false,
	walletsLoading: false,
	invoicesError: '',
	walletsError: '',
	paymentError: '',
	md5hash: md5hash || '',
	orgCode: orgCode || '',
	user: user || '',
	totalAmount: 0,
};

export const AuthReducer = ( currentState, action ) => {
	switch ( action.type ) {
		case 'STORE_HASH':
			return {
				...currentState,
				md5hash: action.payload,
			};
		case 'STORE_ORG_CODE':
			return {
				...currentState,
				orgCode: action.payload,
			};
		case 'REQUEST_AUTH':
			return {
				...currentState,
				loading: true,
			};
		case 'AUTH_SUCCESS':
			return {
				...currentState,
				loading: false,
				user: action.payload,
			};
		case 'AUTH_ERROR':
			return {
				...currentState,
				errorMessage: action.error,
				loading: false,
				loginViaRefresh: action.loginViaRefresh || false,
			};
		case 'GET_INVOICES_SUCCESS':
			return {
				...currentState,
				invoices: action.payload.invoices,
				totalAmount: action.payload.total,
				payment: '',
				paymentError: '',
			};
		case 'GET_INVOICES_ERROR':
			return {
				...currentState,
				invoiceError: action.error,
				payment: '',
				paymentError: '',
			};
		case 'CREATE_WALLET_PENDING':
			return {
				...currentState,
				paymentError: '',
				loading: true,
			};
		case 'CREATE_WALLET_SUCCESS':
			return {
				...currentState,
				paymentError: '',
				loading: false,
				wallets: [
					action.payload,
					...currentState.wallets
				],
			};
		case 'CREATE_WALLET_ERROR':
			return {
				...currentState,
				paymentError: action.error,
				loading: false,
				createWalletError: action.error,
			};
		case 'GET_WALLETS_PENDING':
			return {
				...currentState,
				...createWalletInitialState,
				walletsLoading: true,
			};
		case 'GET_WALLETS_SUCCESS':
			return {
				...currentState,
				wallets: action.payload.wallets,
				walletsLoading: false,
			};
		case 'GET_WALLETS_ERROR':
			return {
				...currentState,
				walletsError: action.error,
				walletsLoading: false,
			};
		case 'REQUEST_PAYMENT':
			return {
				...currentState,
				payment: '',
				paymentError: '',
			};
		case 'PAYMENT_SUCCESS':
			return {
				...currentState,
				payment: action.payload,
				paymentError: '',
			};
		case 'PAYMENT_ERROR':
			return {
				...currentState,
				paymentError: action.error,
			};
		case 'UPDATE_INVOICES':
			return {
				...currentState,
				invoices: action.payload.updatedInvoices,
				invoiceSelection: action.payload.selectedInvoices,
				creditsApplied: action.payload.creditsApplied || [],
				totalAmount: action.payload.total,
				payment: '',
				paymentError: '',
			};
		case 'LOGOUT':
			return initialState;
		default:
			throw new Error( `Unhandled action type: ${action.type}` );
	}
};
