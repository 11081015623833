import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Grid } from '@mui/material';
import { CustomTextField, DateInput } from '@pg/shared-ui';
import { useTheme } from '@emotion/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useOrgState } from '../../orgContext';
import { authenticateUser, useAuthDispatch, useAuthState } from '../../userContext';

import useResponsive from '../../hooks/useResponsive';

const validationSchema = Yup.object( {
	isDobRequired: Yup.bool(),
	last: Yup
		.string()
		.matches( /^[a-z ,.'-]+$/i, 'Please enter valid name' )
		.max( 40 )
		.required( 'The Last Name is required' ),
	dob: Yup
		.string()
		.matches( /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/, 'Please enter valid date' )
		.when( 'isDobRequired', {
			is: true,
			then: Yup.string().required( 'The Date of Birth is required' )
		} )
} );

const VerifyIdentityForm = ( ) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const authDispatch = useAuthDispatch();
	const { isSm } = useResponsive();
	const { loading, md5hash } = useAuthState();
	const { org } = useOrgState();

	const formik = useFormik( {
		initialValues: { dob: '', last: '', isDobRequired: true },
		validationSchema,
		onSubmit: async ( values ) => {
			const response = await authenticateUser( authDispatch, {
				last: values.last.trim(),
				dob: values.dob,
				md5hash
			} );
			if( !response ) return;
			navigate( '/invoices' );
		}
	} );

	useEffect( () => {
		formik.setFieldValue( 'isDobRequired', org?.isDobRequired !== false );
	}, [ org ] );

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid
				container
				spacing={3}
				direction='column'
				sx={{ px: 1 }}
			>
				<Grid item sx={{ minWidth: isSm ? '20px' : '420px' }}>
					<CustomTextField
						id='last'
						error={formik.touched.last && Boolean( formik.errors.last )}
						fullWidth
						helperText={formik.touched.last && formik.errors.last}
						label='Last Name'
						name='last'
						{...formik.getFieldProps( 'last' )}
					/>
				</Grid>
				{org?.isDobRequired !== false &&
					<Grid item>
						<DateInput
							id='dob'
							error={formik.touched.dob && Boolean( formik.errors.dob )}
							fullWidth
							helperText={formik.touched.dob && formik.errors.dob}
							label='Date of Birth'
							name='dob'
							placeholder='MM/DD/YYYY'
							{...formik.getFieldProps( 'dob' )}
						/>
					</Grid>
				}
				<Grid item>
					<Button
						color='primary'
						disabled={loading || formik.isSubmitting}
						fullWidth
						size='large'
						type='submit'
						variant='contained'
						sx={{ borderRadius: 2, height: '50px', boxShadow: theme.shadows ? theme.shadows[ 6 ] : null }}
					>
						<Typography
							variant='headline2'
						>
							Continue
						</Typography>
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default VerifyIdentityForm;
