/* eslint-disable */

// WHEN YOU AUTOGENERATE, DO NOT SAVE THIS FILE, REVERT IT AND DO NOT OVERWRITE HOW IT IS WRITTEN BELOW

// this is an auto generated file. This will be overwritten

export const onPaymentResult = /* GraphQL */ `
  subscription OnPaymentResult {
    onPaymentResult(id: "<%= id %>") {
      id
      message
      refId
      status
      uniqueRef
      __typename
    }
  }
`;
