import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { useRefreshLogin } from './actions';
import { AuthReducer } from './reducer';

export const AuthStateContext = React.createContext();
export const AuthDispatchContext = React.createContext();

export function useAuthState() {
	const context = React.useContext( AuthStateContext );
	if( context === undefined ) {
		throw new Error( 'useAuthState must be used within a AuthProvider' );
	}
	return context;
}

export function useAuthDispatch() {
	const context = React.useContext( AuthDispatchContext );
	if( context === undefined ) {
		throw new Error( 'useAuthDispatch must be used within a AuthProvider' );
	}
	return context;
}

export const AuthProvider = ( { initialState, children } ) => {
	const [ authState, dispatch ] = useReducer( AuthReducer, initialState );
	useRefreshLogin( {dispatch, user: authState.user, md5hash: authState.md5hash, orgCode: authState.orgCode} );

	return (
		<AuthStateContext.Provider value={authState}>
			<AuthDispatchContext.Provider value={dispatch}>
				{children}
			</AuthDispatchContext.Provider>
		</AuthStateContext.Provider>
	);
};
AuthProvider.propTypes = {
	initialState: PropTypes.object.isRequired,
	children: PropTypes.element.isRequired
};
