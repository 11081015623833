import React, { useEffect } from 'react';
import { PageContainer } from '@pg/shared-ui';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { useAuthState, useAuthDispatch, logout, storeOrgCode } from '../../userContext';
import { useOrgState, resetOrg, useOrgDispatch } from '../../orgContext';

import useResponsive from '../../hooks/useResponsive';

import OrgDetailsBox from '../../components/boxes/OrgDetailsBox';
import VerifyIdentityCard from '../../components/cards/VerifyIdentityCard';
import VerifyPayCodeForm from '../../components/forms/VerifyOrgCodeForm';

const VerifyOrgCode = () => {
	const queryParams = useParams();
	const authDispatch = useAuthDispatch();
	const orgDispatch = useOrgDispatch();
	const navigate = useNavigate();
	const { errorMessage, loginViaRefresh, orgCode: userOrgCode, user } = useAuthState();
	const { org } = useOrgState();
	const { isSm, isMd, isLg, isXl } = useResponsive();

	const orgCode = queryParams?.orgCode;

	const VerifyIdentityCardWrapper = () => (
		<VerifyIdentityCard
			errorMessage={errorMessage}
			loginViaRefresh={loginViaRefresh}
			isSm={isSm}
		>
			<VerifyPayCodeForm />
		</VerifyIdentityCard>
	);

	// save orgCode
	useEffect( () => {
		const userExpiresAt = user?.expiresAt;
		const currentTime = new Date().getTime() / 1000;

		if ( orgCode && ( userOrgCode !== orgCode ) ) {
			logout( authDispatch );
			resetOrg( orgDispatch );
			storeOrgCode( authDispatch, orgCode );
		} else if ( !loginViaRefresh && orgCode && user && ( userExpiresAt > currentTime ) ) {
			navigate( '/invoices' );
		} else if ( !orgCode ) {
			navigate( '/404' );
		}
	}, [ orgCode ] );

	return (
		<PageContainer title='Payground: Verify Your Details' description='this is the identity verification page'>
			<Grid
				container
				justifyContent='center'
				sx={{
					mt: isLg ? 0 : 10,
					mb: isSm ? '150px' : isMd ? '200px' : isLg ? '300px' : 0,
					visibility: org ? 'visible' : 'hidden'
				}}
			>
				<Grid
					item
					sx={ {
						mr: isXl ? 10 : 0,
						maxWidth: isSm ? '100%' : isMd ? '95%' : isLg ? '70%' : isXl && '45%'
					} }
				>
					<OrgDetailsBox
						orgCode={orgCode}
						org={org}
						isXl={isXl}
						isSm={isSm}
						isLg={isLg}
					>
						<VerifyIdentityCardWrapper />
					</OrgDetailsBox>
				</Grid>
				{ ( isXl ) && (
					<Grid item sx={{ maxWidth: isSm ? '200px' : '500px' }}>
						<VerifyIdentityCardWrapper />
					</Grid>
				) }
			</Grid>
		</PageContainer>
	)
};

export default VerifyOrgCode;