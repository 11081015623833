import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

function getPayButtonText( checkedInvoices, values ) {
	if ( values?.isPayPlanActive ) {
	  return 'Create Payment Plan';
	}
  
	if ( values?.paymentMethod === 'Finance Payment' ) {
	  return 'Enroll Now';
	}
  
	return checkedInvoices?.length > 1
	  ? `Pay ${checkedInvoices?.length} Invoices`
	  : 'Pay Invoice';
}

const PaymentButtonText = ( { checkedInvoices, values } ) => {
	const [ payButtonText, setPayButtonText ] = useState( 'Pay Invoice' );

	useEffect( () => {
		setPayButtonText( getPayButtonText( checkedInvoices, values ) );
	  }, [ checkedInvoices, values ] );

	return (
		<Typography variant='button'>
			{payButtonText}
		</Typography>
	);
};

PaymentButtonText.propTypes = {
	checkedInvoices: PropTypes.array.isRequired,
	values: PropTypes.object
};

export default PaymentButtonText;
