import React  from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import FullLayout from '../layouts/fullLayout/FullLayout';
import ContactFullLayout from '../layouts/contactFullLayout/ContactFullLayout';

import Invoices from '../views/Invoices';
import Payment from '../views/Payment';
import PaymentSuccess from '../views/PaymentSuccess';
import FinanceRedirect from '../views/FinanceRedirect';
import Error from '../views/authentication/Error';
import RequireAuth from '../views/authentication/RequireAuth';
import VerifyIdentity from '../views/authentication/VerifyIdentity';
import VerifyOrgCode from '../views/authentication/VerifyOrgCode';

const Router = () => {
	return (
		<Routes>
			<Route path='/' element={<Navigate to='/404' />}/>
			<Route path='contact' element={<ContactFullLayout />}>
				<Route path='/contact/:md5hash' element={<VerifyIdentity />} />
				<Route path='/contact/:md5hash/:rpMd5hash' element={<VerifyIdentity />} />
				<Route path='/contact' element={<VerifyIdentity />} />
				<Route path='/contact/:md5hash/:rpmd5hash/*' element={<Navigate to='/404' />} />
			</Route>
			<Route path='org' element={<ContactFullLayout />}>
				<Route path='/org/:orgCode' element={<VerifyOrgCode />} />
				<Route path='/org' element={<VerifyOrgCode />} />
				<Route path='/org/:orgCode/*' element={<Navigate to='/404' />} />
			</Route>
			<Route
				path='/'
				element={
					<RequireAuth>
						<FullLayout />
					</RequireAuth>
				}
			>
				<Route path='finance' element={<FinanceRedirect />}  />
				<Route path='invoices' element={<Invoices />} />
				<Route path='payment' element={<Payment />} />
				<Route path='success' element={<PaymentSuccess />} />
			</Route>
			<Route path='/*' element={<Navigate to='/404' />}/>
			<Route path='404' element={<FullLayout />}>
				<Route path='/404' element={<Error />} />
			</Route>
		</Routes>
	);
};

export default Router;
