import dayJs from '@pg/helper/lib/helpers/date-helper';
import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import ejs from 'ejs';
import { apiEndpoint } from '../constants';
import {
	createPayment,
	createPaymentFinancing,
	createPaymentPlan,
	createWallet
} from '../graphql/mutations';
import {
	getOrganization,
	getOrganizationPayCode,
	getWallets,
	getContactInvoicesWithCredits
} from '../graphql/queries';
import { onPaymentResult } from '../graphql/subscriptions';

export const ERRORS = {
	default: 'Oops, something went wrong. Please try again.',
	paymentRetryError: {
		payment: 'Oops, something went wrong. Please try your payment again.',
		financing: 'Oops, something went wrong. Please try again.',
	},
	refresh_failed: 'Page Timed out. Please verify your identity again.',
	unauthorized: 'This user is not authorized to make this request.',
	verification_failed: 'Your identity could not be verified. Please try again.',
	createWalletError: 'Oops! Something went wrong with adding your payment method. Please try again.'
};

export function handleGraphQlErrors( response ) {
	const messages = [];
	response?.errors?.forEach( ( error ) => {
		const responseMessages = [];
		error?.originalError?.response?.data?.errors?.forEach( ( responseError ) => {
			responseMessages.push( responseError?.message );
		} );
		messages.push( responseMessages || error?.message );
	} );
	return messages.join( ';' );
}

export function handleAxiosErrors( error ) {
	return error?.response?.data?.errorMessage;
}

export async function verifyIdentity( payload ) {
	const { last, dob, md5hash } = payload;
	const formattedDob = dob ? dayJs( new Date( dob ).toISOString() ).format( 'YYYY/MM/DD' ) : null;

	return axios.post( apiEndpoint( 'verifyIdentity' ), {
		lastName: last,
		dob: formattedDob,
		secureHash: md5hash?.contact,
		rpSecureHash: md5hash?.rp,
		'app-type': 'pg-app-qp'
	} );
}

export function verifyIdentityOrgCode( payload ) {
	const { accountId, dob, lastName, orgCode } = payload;
	const formattedDob = dayJs( new Date( dob ).toISOString() ).format( 'YYYY-MM-DD' );

	return axios.post( apiEndpoint( 'verifyIdentity' ), {
		lastName,
		dob: formattedDob,
		orgCode,
		accountId,
		'app-type': 'pg-app-qp'
	} );
}

export async function requestCreatePayment( input, authToken ) {
	const {paymentInputId} = input;
	let subscription = null;
	const resultPromise = new Promise( ( resolve )=>{
		const query = ejs.render( onPaymentResult,{id:paymentInputId} );
		subscription = API.graphql( graphqlOperation( query, {},`Bearer ${authToken}` ) ).subscribe( {
			next: ( value ) => {
				const {value: {data:{onPaymentResult: response}}} = value;
				subscription.unsubscribe();
				resolve( response );
			},
			error: error => {
				subscription.unsubscribe();
				resolve( {errors:[ error ]} );
			}
		} );
	} )
	const { data: { createPayment: paymentResponse } } = await API.graphql( graphqlOperation( createPayment, { payment: input }, `Bearer ${authToken}` ) );
	if( paymentResponse?.errors ){
		if( subscription ){
			subscription.unsubscribe();
		}
		return paymentResponse;
	}
	return resultPromise;
}

export async function requestCreatePaymentFinancing( input, authToken ) {

	const payload = input;
	payload.financingDetails.dob = dayJs( new Date( payload.financingDetails.dob ).toISOString() ).format( 'YYYY-MM-DD' );

	const { data: { createPaymentFinancing: financingResponse } } = await API.graphql( graphqlOperation( createPaymentFinancing, { financing: payload }, `Bearer ${authToken}` ) );
	return financingResponse;
}

export async function requestCreateWallet( { payload, authToken } ) {
	const { data: { createWallet: createWalletResponse } } = await API.graphql( graphqlOperation( createWallet, {
		...payload
	}, `Bearer ${authToken}` ) );

	return createWalletResponse;
}

export async function requestOrganization( md5hash ) {
	const { data: { getOrganization: org } } = await API.graphql( graphqlOperation( getOrganization, {}, `md5:${md5hash}` ) );

	return org;
}

export async function requestOrganizationByOrgCode( orgCode ) {
	const { data: { getOrganizationPayCode: org } } = await API.graphql( graphqlOperation( getOrganizationPayCode, {}, `orgCode:${orgCode}` ) );

	return org;
}

export async function requestWallets( authToken ) {
	const { data: { getWallets: wallet } } = await API.graphql( graphqlOperation( getWallets, {}, `Bearer ${authToken}` ) );
	
	return wallet;
}

export async function requestInvoicesWithCredits( authToken, mode ) {
	const variables = {
		statuses: [ 'Sent', 'Overdue' ],
		invoiceIds: [],
		invoiceMode: mode,
		includePaymentPlanInvoices: false
	};

	const { data: { getContactInvoicesWithCredits: { invoices, associatedInvoices } } } = await API.graphql(
		graphqlOperation( getContactInvoicesWithCredits, variables, `Bearer ${authToken}` )
	);

	return { invoices, associatedInvoices };
}

export async function requestCreatePaymentPlan( { payload, authToken } ) {
	const { data: { createPaymentPlan: createPaymentPlanResponse } } = await API.graphql( graphqlOperation( createPaymentPlan, {
		paymentPlan: { ...payload }
	}, `Bearer ${authToken}` ) );

	return createPaymentPlanResponse;
}
