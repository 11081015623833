const enrichInvoices = ( invoiceResponse ) => {
	const { invoices = [], associatedInvoices = [] } = invoiceResponse;
	return invoices.map( invoice => ( {
		...invoice,
		creditsDebits: ( invoice?.creditsDebits || [] ).length
			? ( invoice?.creditsDebits || [] ).map( cd => {
				const fullData = associatedInvoices.find( assocInv => assocInv.id === cd.id );
				return fullData ? { ...fullData, ...cd } : cd;
			} )
			: invoice.creditsDebits || [], // Return an empty array if creditsDebits is undefined or null
	} ) );
};

export default enrichInvoices;