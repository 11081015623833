// Calculates the total amount owed (including Credit work)
// Calculates the total amount owed (including Credit work) in dollars
export const calculateTotalAmount = ( invoices ) => {
	let totalCents = 0;

	invoices.forEach( ( invoice ) => {
		if ( typeof invoice.amount === 'number' ) {
			totalCents += Math.round( invoice.amount * 100 );
		}
	} );

	return totalCents / 100;
};

export default calculateTotalAmount;