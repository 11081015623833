import {
	achFormikProps,
	ccFormikProps,
	financeFormikProps,
	walletFormikProps,
} from '@pg/shared-ui';

export const PAYMENT_METHODS = {
	wallet: walletFormikProps.initialValues.paymentMethod,
	cc: ccFormikProps.initialValues.paymentMethod,
	ach: achFormikProps.initialValues.paymentMethod,
	finance: financeFormikProps.initialValues.paymentMethod
};

export const PAYMENT_PLAN_METHODS = {
	wallet: walletFormikProps.initialValues.paymentMethod,
	cc: ccFormikProps.initialValues.paymentMethod,
	ach: achFormikProps.initialValues.paymentMethod,
};

export const UNSUPPORTED_WALLET_PROCESSORS = {
	ach: [ 'nuvei' ],
	cc: []
};
