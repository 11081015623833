import React  from 'react';
import PropTypes from 'prop-types';
import { OrgReducer } from './reducer';

const OrgStateContext = React.createContext();
const OrgDispatchContext = React.createContext();

export function useOrgState() {
	const context = React.useContext( OrgStateContext );
	if ( context === undefined ) {
		throw new Error( 'useOrgState must be used within a OrgProvider' );
	}

	return context;
}

export function useOrgDispatch() {

	const context = React.useContext( OrgDispatchContext );
	if ( context === undefined ) {
		throw new Error( 'useOrgDispatch must be used within a OrgProvider' );
	}

	return context;
}

export const OrgProvider = ( { initialState, children } ) => {
	const [ org, dispatch ] = React.useReducer( OrgReducer, initialState );

	return (
		<OrgStateContext.Provider value={org}>
			<OrgDispatchContext.Provider value={dispatch}>
				{children}
			</OrgDispatchContext.Provider>
		</OrgStateContext.Provider>
	);
};
OrgProvider.propTypes = {
	initialState: PropTypes.object.isRequired,
	children: PropTypes.element.isRequired
};
