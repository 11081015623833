import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { CustomTextField, DateInput } from '@pg/shared-ui';
import { useFormik } from 'formik';
import { useTheme } from '@emotion/react';
import * as Yup from 'yup';

import { authenticateUserOrgCode, useAuthDispatch, useAuthState } from '../../userContext';

const validationSchema = Yup.object( {
	lastName: Yup
		.string()
		.matches( /^[a-z ,.'-]+$/i, 'Please enter valid name' )
		.max( 40 )
		.required( 'The Last Name is required' ),
	dob: Yup
		.string()
		.matches( /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/, 'Please enter valid date' )
		.required( 'The Date of Birth is required' ),
	accountId: Yup
		.string()
		.matches( /./, 'Please enter valid account ID' )
		.required( 'Account ID is required' )
} );


const VerifyOrgCodeForm = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const authDispatch = useAuthDispatch();
	const { loading, orgCode } = useAuthState();

	const formik = useFormik( {
		initialValues: { dob: '', lastName: '', accountId: '' },
		validationSchema,
		onSubmit: async ( values ) => {
			const response = await authenticateUserOrgCode( authDispatch, {
				lastName: values.lastName.trim(),
				dob: values.dob,
				accountId: values.accountId,
				orgCode
			} );

			if ( !response ) return;

			navigate( '/invoices' );
		}
	} );

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid
				container
				spacing={3}
				direction='column'
				sx={{ px: 1 }}
			>
				<Grid item>
					<CustomTextField
						id='lastName'
						error={formik.touched.lastName && Boolean( formik.errors.lastName )}
						fullWidth
						helperText={formik.touched.lastName && formik.errors.lastName}
						label='Last Name'
						name='lastName'
						{...formik.getFieldProps( 'lastName' )}
					/>
				</Grid>
				<Grid item>
					<DateInput
						id='dob'
						error={formik.touched.dob && Boolean( formik.errors.dob )}
						fullWidth
						helperText={formik.touched.dob && formik.errors.dob}
						label='Date of Birth'
						name='dob'
						placeholder='MM/DD/YYYY'
						{...formik.getFieldProps( 'dob' )}
					/>
				</Grid>
				<Grid item>
					<CustomTextField
						id='accountId'
						error={formik.touched.accountId && Boolean( formik.errors.accountId )}
						fullWidth
						helperText={formik.touched.accountId && formik.errors.accountId}
						label='Account ID'
						name='accountId'
						{...formik.getFieldProps( 'accountId' )}
					/>
				</Grid>
				<Grid item>
					<Button
						color='primary'
						disabled={loading || formik.isSubmitting}
						fullWidth
						size='large'
						type='submit'
						variant='contained'
						sx={{ borderRadius: 2, height: '50px', boxShadow: theme.shadows ? theme.shadows[ 6 ] : null }}
					>
						<Typography variant='headline2'>
							Continue
						</Typography>
					</Button>
				</Grid>
			</Grid>
		</form>
	)
};

export default VerifyOrgCodeForm;