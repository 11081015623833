function convertDOBFormat( inputDOB ) {
	// For falsey values return a placeholder
	if ( !inputDOB ) return '-'

	// Parse the inputDOB in YYYY-MM-DD format
	let parts = inputDOB.split( '-' );

	// FIXME: Remove clause after PAY-726 resolved
	// and make "parts" an immutable const
	if ( parts.length < 3 ) {
		parts = inputDOB.split( '/' );
	}
	
	const year = parts[ 0 ];
	const month = parts[ 1 ];
	const day = parts[ 2 ];
	// Format the date into MM-DD-YYYY format
	const formattedDOB = `${month}/${day}/${year}`;
	return formattedDOB;
}

export default convertDOBFormat;