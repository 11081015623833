import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Divider, Button } from '@mui/material';
import { HeaderCard, OrgHeader, PageContainer, SuccessImageSvg } from '@pg/shared-ui';
import { useAuthState } from '../userContext';
import { useOrgState } from '../orgContext';
import { cloudfrontEndpoint } from '../constants';

const PaymentSuccess = () => {
	const { totalAmount, payment } = useAuthState();
	const { org } = useOrgState();
	const navigate = useNavigate();

	// Coming from payments, we don't want to be scrolled down on the page, we want to start at the top
	useEffect( () => {
		window.scrollTo( 0, 0 );
	}, [] );

	useEffect( () => {
		if( !payment || !payment.uniqueRef ) {
			navigate( '/404' );
		}
	}, [ payment ] );

	return (
		<PageContainer title='Payground: Success' description='Successful payment!'>
			<HeaderCard header={<OrgHeader org={{ ...org, logo: `${cloudfrontEndpoint()}/${org.logo}` }} />}>
				<Grid
					container
					spacing={2}
					direction='column'
					alignItems='center'
				>
					<Grid item>
						<SuccessImageSvg width='142px' height='144px' />
					</Grid>
					<Grid item>
						<Typography align='center' variant='h5'><strong>Paid!</strong></Typography>
					</Grid>
					<Grid item>
						<Typography
							align='center'
							variant='h6'
						>
							<strong>That&apos;s {totalAmount ? `${totalAmount?.toLocaleString( 'en-US',
								{
									style: 'currency',
									currency: 'USD'
								} )} of` : 'some'} weight off your shoulders.</strong>
						</Typography>
					</Grid>
					<Grid item>
						<Typography align='center' maxWidth='500px' variant='body1'>
							Your payment has been sent {org?.name ? `to ${org?.name}.` : '.'} Your payment will show up
							as &quot;PayGround&quot; on your
							statement.
						</Typography>
						<Divider sx={{ pt: 2 }} />
					</Grid>
					<Grid item>
						<Typography align='center' variant='body1'>
							Let&apos;s get those other medical bills paid, too.
						</Typography>
					</Grid>
					<Grid item>
						<Typography align='center' variant='h6'>
							<strong>Download the PayGround App</strong>
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1' justifyContent='flex-start'>
							• Pay any provider in one place<br />
							• Store payment methods<br />
							• View transaction history
						</Typography>
					</Grid>
					<Grid item>
						<Button sx={{ borderRadius: 2, height: '50px', minWidth: '250px' }} color='primary' variant='contained' target='_blank' rel='noopener'
						        href='https://home.payground.com/pay/?utm_source=successscreen&utm_campaign=csp'>
							<Typography variant='button'>Get The App</Typography>
						</Button>
					</Grid>
				</Grid>
			</HeaderCard>
		</PageContainer>
	);
};

export default PaymentSuccess;
