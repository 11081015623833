import React from 'react';
import PropTypes from 'prop-types';

const LoadingStateContext = React.createContext( {
	loadingCount: 0,
	showLoading: () => {
	},
	closeLoading: () => {
	}
} );

export function useLoadingState() {
	const context = React.useContext( LoadingStateContext );
	if ( context === undefined ) {
		throw new Error( 'useLoadingState must be used within a LoadingProvider' );
	}

	return context;
}

export const LoadingProvider = ( { children } ) => {
	const showLoading = () => {
		toggleLoading( prevState => {
			return {
				...prevState,
				loadingCount: prevState.loadingCount + 1
			};
		} );
	};

	const hideLoading = () => {
		toggleLoading( prevState => {
			return {
				...prevState,
				loadingCount:
					prevState.loadingCount > 0 ? prevState.loadingCount - 1 : 0
			};
		} );
	};

	const loadingState = {
		loadingCount: 0,
		showLoading,
		hideLoading
	};

	const [ loading, toggleLoading ] = React.useState( loadingState );

	return (
		<LoadingStateContext.Provider value={loading}>
			{children}
		</LoadingStateContext.Provider>
	);
};
LoadingProvider.propTypes = {
	children: PropTypes.element.isRequired
};
