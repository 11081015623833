import React  from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

const app = document.getElementById( 'root' );
if( app ) {
	// 1. Set up the browser history with the updated location
	// (minus the # sign)
	const path = ( /#!(\/.*)$/.exec( window.location.hash ) || [] )[ 1 ];
	if( path ) {
		window.history.replaceState( null, null, path );
	}
	ReactDOM.render(
		<BrowserRouter>
			<App />
		</BrowserRouter>,
		app,
	);
}

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
